import React from 'react';
import styled from 'styled-components';

function RecordForm(props) {
  const {record} = props;

  return (
    <Wrapper>
      <div>{JSON.stringify(record)}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 20px 40px 20px;
`;

export default RecordForm;
