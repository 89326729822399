import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Table, Button, Modal, Input} from 'antd';
import * as AppContext from '../../AppContext';
import RecordForm from './RecordForm';

const queryString = require('query-string');

function RecordItem(props) {
  const {record, onClick} = props;
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={{marginRight: 10}}>{JSON.stringify(record)}</div>
      <Button onClick={onClick}>EDIT</Button>
    </div>
  );
}

function HumanRuleEditorPage(props) {
  const app = React.useContext(AppContext.Context);
  const [project, setProject] = React.useState(null);
  const [records, setRecords] = React.useState([]);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const params = queryString.parse(props.location.search);
  const {pid, version} = params;

  React.useEffect(() => {
    async function fetchData() {
      if (!pid || !version) {
        return;
      }

      app.actions.setLoading(true);
      try {
        setProject(await app.actions.staffGetProjectById(pid));
        setRecords(
          await app.actions.staffGetHumanRuleRecords({project: pid, version}),
        );
      } catch (ex) {
        //pass
      }
      app.actions.setLoading(false);
    }

    fetchData();
  }, [app.actions, pid, version]);

  return (
    <>
      <Wrapper>
        <div className="main">
          <div className="header">
            <Button
              type="primary"
              onClick={() => {
                navigate(`/project?id=${pid}&type=rule`);
              }}>
              All Versions
            </Button>
          </div>
          <h2>
            Human Rule {project?.name || '---'} / {version || '---'}
          </h2>
          <div>
            {records.map((record, idx) => (
              <RecordItem
                key={idx}
                record={record}
                onClick={() => setSelectedRecord(record)}
              />
            ))}
          </div>
        </div>
      </Wrapper>

      <Modal
        title={null}
        footer={null}
        bodyStyle={{padding: 0}}
        width={500}
        visible={!!selectedRecord}
        onOk={() => {
          setSelectedRecord(null);
        }}
        onCancel={() => {
          setSelectedRecord(null);
        }}>
        {selectedRecord && <RecordForm record={selectedRecord} />}
      </Modal>
    </>
  );
}

const Wrapper = styled.div`
  padding: 40px;
  background-color: white;

  & > .main {
    & > .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }
  }
`;
export default HumanRuleEditorPage;
